import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance </AnchorLink>
      <AnchorLink mdxType="AnchorLink">Format</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Form logic</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Validation & errors</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <h3 {...{
      "id": "keep-it-short"
    }}>{`Keep it short`}</h3>
    <p><em parentName="p">{`Forms`}</em>{` are used for submitting data so be as concise as possible when designing. Think about each field and what value the data will provide. What do you gain by collecting this information?`}</p>
    <p><strong parentName="p">{`Begin by asking:`}</strong></p>
    <ul>
      <li parentName="ul">{`Is this a piece of information that is valuable to us?`}</li>
      <li parentName="ul">{`Is this a piece of information that is so valuable that it’s worth preventing the user from continuing if they choose not to provide it?`}</li>
    </ul>
    <h3 {...{
      "id": "labels"
    }}>{`Labels`}</h3>
    <p>{`Effective form labeling helps users understand what information to enter into a Text Input. Using a placeholder text as a label is often applied as a space-saving method. However, this is not recommended because it hides context and presents accessibility issues.`}</p>
    <p><strong parentName="p">{`Accessibility best practices for labels:`}</strong></p>
    <ul>
      <li parentName="ul">{`Labels must be visible when an input gets focus.`}</li>
      <li parentName="ul">{`Labels must be announced to the screen reader on focus.`}</li>
      <li parentName="ul">{`Ensure the helper text that appears under an input is read when an assistive technology user stops at an input using ARIA.`}</li>
      <li parentName="ul">{`Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized).`}</li>
    </ul>
    <h3 {...{
      "id": "default-values"
    }}>{`Default values`}</h3>
    <p>{`Where possible, add programmatic assistance. Detect and pre-fill inputs to reduce errors and save time. When the software can’t determine the value that belongs in an input, use type-ahead to make suggestions. Use sentence-case for default values, detected values, and auto-completion text.`}</p>
    <h3 {...{
      "id": "help-text"
    }}>{`Help text`}</h3>
    <p>{`Help text is pertinent information that assists the user in completing a field. Help text is always available when the input is focused and appears underneath the label. Use sentence-style capitalization, and in most cases, write the text as full sentences with punctuation.`}</p>
    <h3 {...{
      "id": "placeholder-text"
    }}>{`Placeholder text`}</h3>
    <p>{`Placeholder text provides hints or examples of what to enter. Placeholder text disappears after the user begins entering data into the Input and should not contain crucial information. Use sentence-style capitalization, and in most cases, write the text as a direct statement without punctuation.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <p>{`All forms are comprised of 6 elements:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Labels:`}</strong>{` Inform users what the corresponding input fields mean.`}</li>
      <li parentName="ul"><strong parentName="li">{`Input fields:`}</strong>{` Enable users to provide information. Information can be entered through a variety of different input fields ranging from text fields, checkboxes, and many other types.`}</li>
      <li parentName="ul"><strong parentName="li">{`Help text:`}</strong>{` Provides assistance on how to fill out a field. Help text is optional.`}</li>
      <li parentName="ul"><strong parentName="li">{`Placeholder text:`}</strong>{` Hints at what goes into a field. Placeholder text is optional.`}</li>
      <li parentName="ul"><strong parentName="li">{`Actions:`}</strong>{` Allow users to submit a form.`}</li>
      <li parentName="ul"><strong parentName="li">{`Validation:`}</strong>{` Ensures the data submitted by the user conforms to acceptable parameters.`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.32608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAAC/klEQVQ4y51US0wTURR9LWCnBWK0IC5I/C0EETXGhQ2prU5jdWHqp8LGKjW6LwSb2iYIKUFoQyBiSDQiBBeF2gRFhRhTvgGlgB06MEzbqaEFwmcBK9PleGdCCWRKF97k5LyZ9+6Z+8598xDaDpZld5jDwsICCofDKBaLoenpaTQ2NiYeHR0Vj4+Pi9bW1lAoFEIMw/A5Cf7v4D6wsbGxI8R9WBB2u53nuro6aXV19ZFoNJoJVWRChVKoTjY0NIQPDw/fHhwcPL07L6kYF01NTQnOcjgc+YuLiwdBUL60tITRNI37/X4vVDZAkuQAVOYBIY7LtrcsFgg2Njby3NzcLKqtrU3nfARB2erqagaInZiZmTH4fL7Subk5JQhcBmgAx1J6WF9fv+cZGnNgfX1d5vF4DkMz7kGFT6Cy8/F4PDuxJhKJpN5yS0sLcrlc/NjpdIqgUgyGmNfrfbC1tfVieXm5AWABaACZ3DpgoaBGo+FZq9UihUKB9Ho9kkgkO/PgnQ62b4bkZ4DHgAucv/sKcqFSqfY9KgRBFECjrqysrBTDOTwDKITqRdzc5uamMEGtVvOM43haSUmJTKlUYuXl5dj2ubs4MTFhh4Ntg/E18K0I/L0E3c/n5imKEgq2trby3NbWJgXv8sDT3K6urtxfk5NpQZrWkoGAd5Yg+qj5+e8RhvkBJ+BnMBh8yOWAsDhlY5JF95e+U739/eeev+3I2v0+RvhESRMqKip4tlgsmNFoPGo2mw/9YRi+i2GSvEVOTUVnJ30h2v/bH6VIb5QKTMWogJEXpQLCCt1ud4IzAFngC0ZTlBSMT+/t6DzZ295R5nr96s6H9s/3e/pCZZ3uecO7buosl/O+hxLt+6eAf9Kampq8kZGRbD9BZIOg+K5ejzc4nF9vXFd9LNC99Jg+sT05lew39Igt5ZOessk91Ol0Sd/jarWkqLAo5+ZVhVxheCNHBlZ+vPJvTrE1LuXmCyxxYZLVauXZZrOhqqoqBLcLfy+aTKaUV1leJbvn+R9GSKyS1nr+pgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "effective form design",
            "title": "effective form design",
            "src": "/static/0fe839eabc11470815415f061cee0e9c/fb070/form-usage-1.png",
            "srcSet": ["/static/0fe839eabc11470815415f061cee0e9c/d6747/form-usage-1.png 288w", "/static/0fe839eabc11470815415f061cee0e9c/09548/form-usage-1.png 576w", "/static/0fe839eabc11470815415f061cee0e9c/fb070/form-usage-1.png 1152w", "/static/0fe839eabc11470815415f061cee0e9c/c3e47/form-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "button-placement"
    }}>{`Button placement`}</h3>
    <p>{`On non-modal or in-page forms, align single buttons or Secondary / Primary button groups to the left. Buttons should align with the form controls regardless of the user‘s window width.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.95108695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADFElEQVQ4y6WUS0wTQRjHZ8siUstDJEJqUjyIF03w2ASCiTGReJCDSQ+YqGAQxJJwJXo2wUY5NETl4BE4mHjwcTJKU2gqVI0mYFBKH/T9oC3Qlgrb8T/T7doEjRAm+fX7dmb2v99jpoRgNDQ0kKIt+qXz+xotLS3carVaZU6j0RCdTsd9g8Gg2CKl87tGa2srtx0dHYJer29sa2vTplIpMZPJiMxubGyUJ5NJFaVUAGW5XK44r7C+vs414BdEsZEcdChi0WiUW0mSKldXV5+Hw+FXm5ubk9gwBSbAa0Rr8Hq918B7RDMlr7E9L8AY0CiiCL8YZYXP5xsOhUKP1tbWTPF4nDECxhKJxEW/33/B7Xab4Y/Iaw/BY3AfqJkGLCFGo5ELtre3CyaTqZrVCbCaqbLZbBmEBBYBMtCwOiKLMgShwkdL4RrclkSoCQaDdkThX1lZcSFaD5rhwTrDLeMpgT0HgB3UMg1YgTgcDi64tLQk2Gy2k1artdlisZxaWFhoQs10/4HtOQFUTAOWEKTABWHLQQ9SHAKDKH4vmtMDbv0DttaHcnSBil1NgYAa/kswh1RnUQ/7HphFkyZAFdOAPdg53N7ebkJnL0G4UmkKbzVGPp9XBwKBNx6P5xtStqFBVjCDYzSD+RnmY55beX4aRBDVajqdrleagrwFOcpqiEVRR+p0Oim6TdFt6nK5uGVzy8vLyjPz8QGKOrKO18k1FEgy9UfQ5fbG3F4fdbo8hZflF4sC7GOIjiIyiqgphChqH8VNOqYI0p2EXMesmm5FJ6V0yJLPhN/FY5G5SCT6KRKJOBi4kg6Ux4GXHBBhzIOvSPltKBSsYRqxWEwglb2/ChXuyoi1AzuXa+5KBk2/1Hn+wdZRSqdVn3/E2HHi/zA4pyJuk2g2m8XR0VFxfHxcfPb0iag/d4ZLDBkH8NtNecrkCq06cof+PNSHlt+kMXKdNstnYE8d77raWXAO35a4PT0sCeSGVC/05BvV/dLxs/dyIqWD5MOXkIBrSP4FmkfmP9q5xvfFxYJo3WD+75/tzu/7bP4GwGQezi3Od4AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Primary button alignment in Forms",
            "title": "Primary button alignment in Forms",
            "src": "/static/8f34efcad01718b7a5308a6a01fb57b8/fb070/form-usage-5.png",
            "srcSet": ["/static/8f34efcad01718b7a5308a6a01fb57b8/d6747/form-usage-5.png 288w", "/static/8f34efcad01718b7a5308a6a01fb57b8/09548/form-usage-5.png 576w", "/static/8f34efcad01718b7a5308a6a01fb57b8/fb070/form-usage-5.png 1152w", "/static/8f34efcad01718b7a5308a6a01fb57b8/c3e47/form-usage-5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Single button alignment in forms</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.95108695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADP0lEQVQ4y6WUSUxTQRjH50Eb01KWKElJjcWDXPSAF0lZBGWRBjSaqJXgRYsEgzVijAcTj16wUQ4NETlwBA4mHFwuEpUUCAoeNAFD0pVC+6AtSykU7DL+Z2hfmqABwyS/fN+bmfefb5n3CMFQq9UkZVN++vx/jeLiYm41Go00p1KpiFar5b7BYJBsivT5XaO8vJxbvV4v6HS6goqKCs3a2ppsc3NTxuz6+rp8dXU1g1IqgMzt7e3UvEQoFOIa8HdEsZEcdEhifr+f23g8rvB4PH2Li4tvw+HwADYMgn7wDtEa5ubmboJPiGYwucb2vAHdQCWJIvxUlIfm5+efiKL4Ynl52RwMBhmdoHtlZaV2YWGh2uVyWeB3Jteeg5fgKVAyDVhCTCYTF6ysrBTMZnMOqxNgNcuIRCKZEBJYBMhAxeqILDIRRAYOTYdrcJsWocrn800gigWHw+FEtG40w411hiuJOw327AUTII9pwApkamqKC87Ozgrj4+PHrVZr0cjIyInp6elC1Ey7B2zPUZDBNGAJQQpcEFYOjEixA9xH8VvRHCNo+QdsrQ3laAaHdjUFAkr4Q+AbUh1DPSb2wRia1A+ymQbswe5hNBotRGfrIayQmsJbjZFIJJRer/e92+3+iZTH0SArGMU1GsX8KPMxz21y/gtYQlSejY2NfKkpyFtIRpkDMT/qSO12O0W3KbpNnU4nt2zOZrNJz8zHARR1ZB0/nKyhQFAvSRAiAYgmX7BTp2uO2uwQ4NZB2WGIjiIyiqgphChq78eXdEQSTNUPVomaDGxtbY38jkY/htdD1mhY/JzYEIdpRBwOLYtjgWDwO16agghjEvxAyh9E0ZfLNAKBgEBqamq4YElJiQx/nIaGxovXL9SeN1RfaT2T94Dqc9uj17Luxm9oHsZPsrMfPeuTWywWWVdXl6y3t1f2uueVTHf6FNfoMLUTgt8VT7moqCi7rq7OVl+vj50t19HiqqYehYnOyO/EYqQFUrfoY/7WMSr8rePNVy/vOKkIm5qahLKysnxdaam6trqq4NwlYw65TfMzjVG1oi1RkHcvlsX2DY0tCfgsSQrUnUx+neAav2ZmdkQbGxv3d/GMe9/XP2loERRQaYUTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Secondary / Primary button alignment in forms",
            "title": "Secondary / Primary button alignment in forms",
            "src": "/static/39a453015e6776301a3a1514a9c3375f/fb070/form-usage-6.png",
            "srcSet": ["/static/39a453015e6776301a3a1514a9c3375f/d6747/form-usage-6.png 288w", "/static/39a453015e6776301a3a1514a9c3375f/09548/form-usage-6.png 576w", "/static/39a453015e6776301a3a1514a9c3375f/fb070/form-usage-6.png 1152w", "/static/39a453015e6776301a3a1514a9c3375f/c3e47/form-usage-6.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Secondary / Primary button alignment in forms</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "multi-step-forms"
    }}>{`Multi step forms`}</h3>
    <p>{`When the primary action implies a navigation step forward, as in a wizard, align single buttons or Secondary/Primary button groups to the right. This position conveys the “next step” intention.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.80434782608697%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADEElEQVQ4y62UTUhUURTH73ujomg2CjUj5RRWi6BF6xZBuYvAWmQhgZaWCxPHkjbWZItILYlIJdSpjZTpTkJnBBEXLSRSkNRyBr+/mHnzoTPT2Iy+0/++5zOT1D688OOc+/H+nHPPeZexXRoOh0N1xLwIY0TqpJIEdo90LDcUw/KijLkxbyMx7ibpiEgEAtAFg0Hdmi8ODQ3FTE5OKp87nU7GEgpXVbGXJOizP6QZslr2mSooIb5QPpp6rvmI8XT5gfTSb8m3yyuN5pLiQ62tbak2m01vNptNNTU1Bo/HkzQ4OChyibGxsY1B03+l7PV6VcfW0a7YrvZmobOzI+PLV4dJJjLSStBAsmz4HiXjYiCUFo1GjUjREA6HjRzuRyIRI9I3hkKhWK4By9ji4qIaH1HCwsLCwNTUVBC44XuXlpa82N8KD/ABFzjJNWBF1tTUpAharVbW2NiYXF9fr7dYLCktLS17+vr6Eru7u5N6enp+R+IGq9whLGNahWBjJyYmihHdA/j33W53GSI0I6XSLdD2SsB+rgErMHykpRwnSdKr+fn5bpfL1YGU7RDt8vl8XbhwxW7CvmY7wTGuASvsVl+rBVlT1SKMn52dfT83N/cZUX/CBffvAD8zAD6C4+tFQZqaoA5i5yGag2a9jPUrO6CdyQYpXEPRstvtimBDQ4PQ29t7WJZl3nO8x/4IfhbEcA3M2caiJKAg/ShGCGtaj20H70U/rszt9/t/9uGmofvXopD2wODetIXY8fHxCtzhc1TscSAQqEJfVXICa9a/FKz2+oNPN+LxB6vBQa4BKzCEu96HMzMzz6anp9uQxhv03lsNH5A83nerYY+VZKmWVqU6UAu/jkh6AUyqhiT8Gu5OI5POsqtUzXLIAlvBLtFDlgX/IqUp+xfwfi4vL6+LjoyMiMPDwyKqJmJ9nRuvV+L4GbyRFn2RLO0tkkdTi2Un5mNCvjyqK5BPKI91viwyVFUR5HYr+Mi4u7r9b3XtL95TU5l6WH+LhDNVJJ56RELmExLS7yDFXBJiCmT14HViPwC+zR/YpEWHWQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Primary button aligment in a wizard",
            "title": "Primary button aligment in a wizard",
            "src": "/static/2d1c0ba78b57b4deef85bd3f95facc80/fb070/form-usage-7.png",
            "srcSet": ["/static/2d1c0ba78b57b4deef85bd3f95facc80/d6747/form-usage-7.png 288w", "/static/2d1c0ba78b57b4deef85bd3f95facc80/09548/form-usage-7.png 576w", "/static/2d1c0ba78b57b4deef85bd3f95facc80/fb070/form-usage-7.png 1152w", "/static/2d1c0ba78b57b4deef85bd3f95facc80/c3e47/form-usage-7.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Single button aligment in a wizard</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.94021739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADVUlEQVQ4y62UX0hTYRjGv2+bqRDaVu0opVHp0AryRrowHUSIQaUXFVL2RyvElVhdrVV2E5ldpCSRWRHVTcHKNNRRsDACgzI1kLSZ+X9ubu7M1JXbztdzzjbLoJLywI/nPd/Z9+x9z/uej5AFuiwWSyCgh3yEMBa4ucAoOc3k5OC0guT7KWnH/T0miyhkCsaYDNAZr08+OTkpF2Mg7+zsVAwMDEjbe3p6CIks9AfMrjOq3PMqlst+uDzuPIuMKBQSVNvuruEyzqyIPzkddcpQFnOipHiV0fhI2djYuKSkpCS+oqJC7XQ6F7e3t8tEi97e3oBXKMH/ucbHxwNBw9Mnkj55UENNTU2rLT2f41AKx5hHzQRB/c3H1O4v05zX68MaU3s8XzmPxyPFMzMzHMrnpqamFKIHlBCedwezZJFWq7UV74MH1tHRUfvExITd7Xb/DhsYA8NgvegBlZH6+nrJ0GQy0dra2mVGo5GrqamJMZvNS7u6upRtbW2qjo6OP6EEctEDSkhfX59k2N/fH4ZYBzUAvd1uP4EMj6Ok4t8QeqYDy0QPKCXYFCo53OFw3EepL8fGxp5DzdAXLpfL/AfE58/AWtEDShdqrsXs5rYbGUYMDw83jIyMvEfWb/CCW//CW/AOvAaJwaZQgsEMGcphuHNoaCgPa3tR/nzYB3LBEtEDSgimXjKsqqqizc3Nq2Ac4/V61fPF7/eLKs0hlPzcFGkOAZYmxPly/AXxN040wsrz/I85TE5Onn2xaWlp4SqVSvFf3UETQhmG4eMuxTu8ggzL8M+Xf4bnXeW8+8ulcX7ycpDyoJaBWNHDyWMOkW7IcBGacnVwcPCxz+e7jftqcCOIGN9iX113mN9RzXyOG6CaCVDmuAZdGfBw0JDZnKwTExN3paamlqWkpJzTaDSlmqR1FzRxUYVki3UH2c8ukr3sHMljpWQ3O0+y2VmSwzhpYw7OT5wcUqzX60ldXZ30TWZmZt7MysoahXZrtdqPGdotQ1s3bzRFFdgro48za/QxoVtZLFhwZn6SHRY+yI8ISeI+xDJis9nmZNfS0vJvzcj/5VBNT0+fLb2oqIgYDAaq0+ko1unmdC3N2LSBrjhmI3uqGd1eKdBcaIIeJR5gNOxo0KyAke/v5xZsT9Vp6QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Primary button aligment in a wizard",
            "title": "Primary button aligment in a wizard",
            "src": "/static/1c53041897d5028a44c6e435149eaf57/fb070/form-usage-8.png",
            "srcSet": ["/static/1c53041897d5028a44c6e435149eaf57/d6747/form-usage-8.png 288w", "/static/1c53041897d5028a44c6e435149eaf57/09548/form-usage-8.png 576w", "/static/1c53041897d5028a44c6e435149eaf57/fb070/form-usage-8.png 1152w", "/static/1c53041897d5028a44c6e435149eaf57/c3e47/form-usage-8.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Secondary / Primary button pair alignment in a wizard</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "form-logic"
    }}>{`Form logic`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Radio buttons`}</strong>{` are used when there is a list of two or more options that are mutually exclusive and the user must select exactly one choice. In other words, clicking a non-selected radio button will deselect whatever other button was previously selected in the list.`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkboxes`}</strong>{` are used when there are lists of options and the user may select any number of choices, including zero, one, or several. In other words, each checkbox is independent of all other checkboxes in the list, so checking one box doesn’t uncheck the others. A stand-alone checkbox, or a toggle can be used for a single option that the user can turn on or off.`}</li>
      <li parentName="ul"><strong parentName="li">{`Select elements`}</strong>{` are used for fields in which a single selection is required and there are a large number of possible options.`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.44642857142858%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABVUlEQVQoz2NgQAcsjAzEgv///4MwEwj//v2bGSwoZ6nKIMotxAYyik+InxUkJioqCqI5gJgZppmdnZ0FRIGwgYEBflvV0syZy3d38ub7pjGB+IqKisxHjx6Vu3jxoihMTVBQEPvp06d1jxw5IgwTu3DhgvDJkycrgXTzrVu3YuAG2p1K4Wq8MdMT5HQQ38zcjPPRo0cVz58/z799+zYXSCwxMVH4wYMHza9evXKE6Xvz5o3O169f/4PAu3fvbmC4VDnGGEw7ODqwAA00AxpoDaTBBq5fv57l2bNnxk+fPlWCqf/w4YPC69evj799+/b6p0+f5iUlJTEwSEhIMIiLi7M5M/DCDebh4WFCD3wgYELjMzg5OTElJydzHj9+nAXoE0h4i/ILM0hISTDGzM1luLXhPFhMXl6eoaGhgXnlypXMMM0mJiYMNTU1LFevXsUZIT4+PgwAHLyM+tBJhFsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "form logic",
            "title": "form logic",
            "src": "/static/edc1d596c09fbfe374c5772bd92ac4d3/fb070/form-usage-4.png",
            "srcSet": ["/static/edc1d596c09fbfe374c5772bd92ac4d3/d6747/form-usage-4.png 288w", "/static/edc1d596c09fbfe374c5772bd92ac4d3/09548/form-usage-4.png 576w", "/static/edc1d596c09fbfe374c5772bd92ac4d3/fb070/form-usage-4.png 1152w", "/static/edc1d596c09fbfe374c5772bd92ac4d3/fb104/form-usage-4.png 1728w", "/static/edc1d596c09fbfe374c5772bd92ac4d3/8fefe/form-usage-4.png 2240w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "validation--errors"
    }}>{`Validation & errors`}</h2>
    <h3 {...{
      "id": "error-messaging"
    }}>{`Error messaging`}</h3>
    <p>{`Effective error messaging can help the user to understand the problem and how to fix it. First, inform the user what has happened, then provide guidance on next steps or possible resolutions. `}<a parentName="p" {...{
        "href": "/components/notification"
      }}>{`Inline notifications`}</a>{` can be used to state the general problem with the users input, such as `}<em parentName="p">{`“Please input the required fields.”`}</em>{` Inline Notifications can occur pre or post submission, depending on the type of data the user is inputting.`}</p>
    <h3 {...{
      "id": "form-validation"
    }}>{`Form validation`}</h3>
    <p>{`We recommend validating the users data before form submission. Use visual cues to guide the user as to where the problem lies within the form. This will help to easily identify the elements that need to be corrected.`}</p>
    <p>{`The validation should appear when the user has clicked away from the text field. Once the user corrects the errors within the text field, the validation should disappear once the data is rendered as valid.`}</p>
    <p>{`The validation label below the field should be as informative as possible when describing the issue with the users data. For example, if password limitations require 16 characters, but the user inputs a password with only six characters, the text should read something like, `}<em parentName="p">{`“Password must be at least 16 characters.”`}</em></p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/ee35d10c18135c7a87a0d560ba9b2b75/form-validation.gif",
          "alt": "form validation"
        }}></img>
      </Column>
    </Row>
    <h3 {...{
      "id": "optional-vs-required-fields"
    }}>{`Optional vs. required fields`}</h3>
    <p>{`All fields in a form are assumed required, with optional fields being tagged as so.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.95108695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADFElEQVQ4y6WUS0wTQRjHZ8siUstDJEJqUjyIF03w2ASCiTGReJCDSQ+YqGAQxJJwJXo2wUY5NETl4BE4mHjwcTJKU2gqVI0mYFBKH/T9oC3Qlgrb8T/T7doEjRAm+fX7dmb2v99jpoRgNDQ0kKIt+qXz+xotLS3carVaZU6j0RCdTsd9g8Gg2CKl87tGa2srtx0dHYJer29sa2vTplIpMZPJiMxubGyUJ5NJFaVUAGW5XK44r7C+vs414BdEsZEcdChi0WiUW0mSKldXV5+Hw+FXm5ubk9gwBSbAa0Rr8Hq918B7RDMlr7E9L8AY0CiiCL8YZYXP5xsOhUKP1tbWTPF4nDECxhKJxEW/33/B7Xab4Y/Iaw/BY3AfqJkGLCFGo5ELtre3CyaTqZrVCbCaqbLZbBmEBBYBMtCwOiKLMgShwkdL4RrclkSoCQaDdkThX1lZcSFaD5rhwTrDLeMpgT0HgB3UMg1YgTgcDi64tLQk2Gy2k1artdlisZxaWFhoQs10/4HtOQFUTAOWEKTABWHLQQ9SHAKDKH4vmtMDbv0DttaHcnSBil1NgYAa/kswh1RnUQ/7HphFkyZAFdOAPdg53N7ebkJnL0G4UmkKbzVGPp9XBwKBNx6P5xtStqFBVjCDYzSD+RnmY55beX4aRBDVajqdrleagrwFOcpqiEVRR+p0Oim6TdFt6nK5uGVzy8vLyjPz8QGKOrKO18k1FEgy9UfQ5fbG3F4fdbo8hZflF4sC7GOIjiIyiqgphChqH8VNOqYI0p2EXMesmm5FJ6V0yJLPhN/FY5G5SCT6KRKJOBi4kg6Ux4GXHBBhzIOvSPltKBSsYRqxWEwglb2/ChXuyoi1AzuXa+5KBk2/1Hn+wdZRSqdVn3/E2HHi/zA4pyJuk2g2m8XR0VFxfHxcfPb0iag/d4ZLDBkH8NtNecrkCq06cof+PNSHlt+kMXKdNstnYE8d77raWXAO35a4PT0sCeSGVC/05BvV/dLxs/dyIqWD5MOXkIBrSP4FmkfmP9q5xvfFxYJo3WD+75/tzu/7bP4GwGQezi3Od4AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "optional fields in a form",
            "title": "optional fields in a form",
            "src": "/static/8f34efcad01718b7a5308a6a01fb57b8/fb070/form-usage-5.png",
            "srcSet": ["/static/8f34efcad01718b7a5308a6a01fb57b8/d6747/form-usage-5.png 288w", "/static/8f34efcad01718b7a5308a6a01fb57b8/09548/form-usage-5.png 576w", "/static/8f34efcad01718b7a5308a6a01fb57b8/fb070/form-usage-5.png 1152w", "/static/8f34efcad01718b7a5308a6a01fb57b8/c3e47/form-usage-5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      